import Modal from "../Modal"
import React from "react"

export default function TermsModal({className, onClose}) {
  return (
    <Modal
      className={className}
      onClose={onClose}
    >
      <h3>Användarvillkor och information TAGGAD fototjänst</h3>
      <p><b>Köp av Taggad fototjänst</b></p>
      <p>För att se bilder på en deltagare från ett lopp, utöver de miniatyrer som förhandsvisas,
        behöver TAGGAD fototjänst köpas för denna deltagare, genom betalning till TAGGAD AB.</p>
      <p>Vid köp av TAGGAD fototjänst får du en länk via email till ett personligt galleri, där du
        kan se, dela och ladda ner alla deltagarens bilder från loppet. Alla du delar denna länk med
        kan komma åt galleriet.</p>
      <p>TAGGAD fototjänst levererar flera bilder från olika fotoplatser, där det - utifrån
        tillgänglig data - bedöms att den sökta deltagaren ska vara synlig. För att säkerställa att
        det inte missas någon bild, och för att bibehålla snabbast möjliga leveranstid, kommer det i
        många fall även finnas bilder på andra deltagare. Vid tillfällen då många deltagare passerar
        en fotostation samtidigt kan man behöva navigera bland bilderna för att hitta den deltagare
        man söker. Detta är inte ett fel, utan ett förväntat beteende.</p>
      <p>Det råder ingen ångerrätt på TAGGAD fototjänst eftersom bilderna levereras digitalt.</p>
      <p>Reklamation kan vara möjligt vid exempelvis köp av startnummer som saknar bilder senast 24
        timmar efter deltagarens målgång. Kontakta i så fall den mailadress som står angiven längst
        ner på startsidan, där sökrutan finns för det aktuella eventet.
      </p>
      <p>Inga bilder får säljas vidare till tredje part utan skriftligt godkännande från Taggad
        AB.
      </p>
      <p>För redaktionell publicering kontakta Taggad AB via info@taggad.se.
      </p>
      <p><b>Skapa TAGGAD-konto och ta del av extra förmåner som inloggad användare</b>
      </p>
      <p>För att kunna ta del av vissa tjänster, såsom nedladdning av flera bilder, skicka bilder
        till framkallningslabb och lagring av bildgalleri i arkiv, krävs ett personligt konto på
        taggad.se.
      </p><p>I samband med inloggning sparas de personuppgifter du anger såsom namn,
      kontaktinformation och adress i syfte att kunna utföra våra åtagande enligt beställningen.
    </p><p>Att skapa ett TAGGAD-konto är kostnadsfritt.
    </p><p>För att avsluta ett TAGGAD-konto permanent, maila info@taggad.se från den mailadress som
      kontot gäller.
    </p><p><b>Spara i bildarkiv</b>
    </p><p>I "Mitt Bildarkiv" kan du spara dina bildgallerier på ett och samma ställe så att du
      snabbt och lätt kommer åt dem i framtiden. Utöver att Bildarkivet innehåller de bildgallerier
      som du själv som köpt kan du även lägga till bildgallerier som du fått åtkomst till genom att
      någon delat en unik webblänk med dig. Som registrerad användare blir du informerad i god tid
      om det skulle ske några ändringar i de bildgallerier som finns sparade i ditt Bildarkiv.
    </p><p><b>Ladda ner bilder</b>
    </p><p>Bilderna som visas på taggad.se är i en lägre upplösning för att visas digitalt. Genom att välja bilder från
      ditt bildgalleri för nedladdning sparas dessa på din enhet i en högre upplösning.
    </p><p><b>Skicka bilder till vår framkallningspartner</b>
    </p><p><b>Villkor för besökare och kunder på taggad.se</b>
    </p><p>Bilderna som ingår i TAGGAD fototjänst kan innehåller persondata, i form av
      personavbildning av andra personer än den personen som bildköpet avser. Med anledning av GDPR
      kan Taggad AB tvingas radera bilder som innehåller oönskad persondata. Om bilder raderas på
      grund av detta utgår ingen återbetalning.
    </p><p><b>Hantering av kunduppgifter - GDPR</b>
    </p><p>TAGGAD är en fototjänst, beställd av eventets arrangör, och lagrar således persondata i
      form av bilder och bildmetadata enligt överenskommelsen med arrangören.
    </p><p>Användare som skapat ett TAGGAD-konto kan spara bilder, som har blivit köpta, under en
      icke förbestämd tid på sitt personliga TAGGAD-bildarkiv.
    </p><p>För att möjliggöra matchning av bild mot deltagare och för att göra enstaka utskick till
      deltagare via t.ex. sms eller mail, hämtar TAGGAD persondata från arrangörens kundregister
      enligt överenskommelse med arrangören.
    </p><p>I samband med beställning eller upprättande av konto hos TAGGAD sparas de kunduppgifter
      du anger såsom namn, kontaktinformation och adress i syfte att kunna utföra våra åtagande
      enligt beställningen. Vi sparar kunduppgifter om dig tills du återkallar ditt samtycke.
    </p><p>Sparade kunduppgifter kan komma att användas för kontakt via telefon, e-post, brev och
      SMS i syfte att leverera eller informera om våra tjänster. Detta gäller såväl Taggad AB som
      arrangören bakom eventet där bilderna tagits, eller någon av eventets eller TAGGADs officiella
      partners, i enlighet med de Personuppgiftsbiträdesavtal (PUB) som finns mellan dessa parter.
    </p><p>Sparade personuppgifter hanteras enligt datainspektionens riktlinjer. Du har när som
      helst rätt att begära ändring av registrerade uppgifter eller begära att dessa raderas. Detta
      gör du genom att skriftligen meddela Taggad AB via info@taggad.se
    </p><p>Om du finns med på bild från ett event som publicerats på Taggad.se men vill få denna
      bild permanent raderad skickar du URL:n för bilden till info@taggad.se med en kort beskrivning
      varför du vill få bilden raderad. Bildens URL hittas enklast genom att högerklicka (dator)
      eller långklicka (mobil) och välja kopiera bildadress,
    </p>

    </Modal>
  )
}
