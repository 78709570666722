import React, { useReducer, useEffect } from 'react'
import {  
  SET_LOADING_BUY,
  SET_LOGIN_LOADER,
  SET_EVENTS_DATA, 
  SET_USER,
  SET_BACKGROUND
} from './actions';

import { mainReducer } from './reducer';
// import { useGetEvents } from '../services/getRequests'

const defaultState = {
  selectedImagesArray: [],
  user: null, 
  eventsData: null,
  isHere: false,
  isSelectMode: false,
  isLoadingBuy: false,
  isLoginLoading: false,
  background: "none",
}
  
export const MainContext = React.createContext({ 
  ...defaultState, 
  // eslint-disable-next-line no-empty-function
  handleIsLoadingBuy: () => {},
  // eslint-disable-next-line no-empty-function
  handleLoginLoader: (handleLoginLoader) => {},
  // eslint-disable-next-line no-empty-function
  handleEventsData: (eventsData) => {},
  // eslint-disable-next-line no-empty-function
  handleUserLog: (user) => {},
  // eslint-disable-next-line no-empty-function
  setBackground: background => {}
})


export const GlobalProvider = ({children}) => {
  const [state, dispatch] = useReducer(mainReducer, defaultState)

 
 
  const handleIsLoadingBuy = (isLoadingBuy) => {
    dispatch({ type: SET_LOADING_BUY, isLoadingBuy })
  }

  const handleLoginLoader = (isLoginLoading) => {
    dispatch({type: SET_LOGIN_LOADER, isLoginLoading })
  }

  const handleEventsData = (eventsData) => {
    dispatch({type: SET_EVENTS_DATA, eventsData})
  }

  const handleUserLog = (user) => {
    dispatch({type: SET_USER, user})
  }

  const setBackground = (background) => { 
    dispatch({type: SET_BACKGROUND, background})
  }

  // const { data: events } = useGetEvents();


  // useEffect(() => {
  //   handleEventsData(events)
  // }, [events]);

  const value = {
    selectedImagesArray: state.selectedImagesArray,
    eventsData: state.eventsData,
    isSelectMode: state.isSelectMode,
    isLoadingBuy: state.isLoadingBuy,
    isLoginLoading: state.isLoginLoading,
    user: state.user,
    background: state.background, 
    handleIsLoadingBuy: handleIsLoadingBuy,
    handleLoginLoader: handleLoginLoader,
    handleEventsData: handleEventsData,
    handleUserLog: handleUserLog,
    setBackground: setBackground
  }
  return (
    <MainContext.Provider value={value}>
      {children}
    </MainContext.Provider>
  )
}