import React from 'react'
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom"

import TopBar from "./components/TopBar"
import Footer from "./components/Footer"
import Loader from "./components/Loader"
import {auth} from "./firebase"
import {MainContext} from './state/state'
import sendRequest from './services'

import 'react-lazy-load-image-component/src/effects/blur.css'
import {pages} from "./utils/routes"


const HomePage = React.lazy(() => import("./pages/Home"))
const SearchPage = React.lazy(() => import("./pages/Search"))
const ParticipantGallery = React.lazy(() => import("./pages/ParticipantGallery"))

const OrderPage = React.lazy(() => import("./pages/Order/OrderPage"))
const OrderSuccess = React.lazy(() => import("./pages/Order/Success"))
const OrderCancel = React.lazy(() => import("./pages/Order/Cancel"))

const Archive = React.lazy(() => import("./pages/Archive"))

const Login = React.lazy(() => import("./pages/Login"))
const ForgotPassword = React.lazy(() => import("./pages/ForgotPassword"))
const Signup = React.lazy(() => import("./pages/Signup"))


class Router extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      ready: false,
    }
  }


  async componentDidMount() {
    try {
      window.gtag('config', 'G-D3K2DPXN4S', { send_page_view: false })
      let {handleUserLog, handleEventsData} = this.context
      await this.fetchUser(handleUserLog)

      const eventsData = await sendRequest.get("events").then(events => events.data.map(e => ({
        ...e,
        finished: new Date(e.finished),
        started: new Date(e.started),
        hasFinished: (e.finished && new Date(e.finished) < new Date()),
        hasStarted: (e.started && new Date(e.started) < new Date())
      })))
      handleEventsData(eventsData)

      this.setState({ready: true})
    } catch (error) {
      console.log("error at router", error)
    }
  }

  componentWillUnmount() {
    this.log("unmounting", this.props, this.state)

    // aut

    if (this.authListener != null) {
      this.authListener()
    }
  }


  fetchUser(action) {
    return new Promise((resolve) => {
      auth.onAuthStateChanged(user => {
        action(user)
        resolve()
      }, error => {
        resolve(false)
        console.log("error at fetchging user", error)
      })
    })
  }

  //this is for redirecting user to latest page after authorization process
  addRouteListener(event) {
    const authPaths = ["/login", "/signup", "/forgot-password"]

    //at the initialization event is not being called
    if (!authPaths.includes(window.location.pathname)) {
      window.routeBeforeAuth = window.location.pathname
    }


    event?.history.listen((location) => {
      if (!authPaths.includes(location.pathname)) {
        window.routeBeforeAuth = location.pathname
      }
      window.gtag("event", "page_view", {
        page_path: location.pathname + location.search + location.hash,
        page_search: location.search,
        page_hash: location.hash,
      });
    })
  }

  log(...stuff) {
    console.log("Router:", ...stuff)
  }

  render() {
    const featuredEvent = this.context.eventsData?.find(e => e.featured) || this.context.eventsData?.[0]

    if (!this.state.ready) {
      return (
        <Loader/>
      )
    }

    return (
      <div className="taggad-container">
        <BrowserRouter ref={this.addRouteListener}>
          <div className="full">
            <TopBar/>
            <div
              className={"taggad-content"}
            >
              <Switch>
                <Route
                  path={pages.eventHome(":eventId")}
                  component={HomePage}
                />
                <Route
                  path={pages.order(":orderId")}
                  component={OrderPage}
                />
                <Route
                  path={pages.success(":orderId")}
                  component={OrderSuccess}
                />
                <Route
                  path={pages.cancel(":orderId")}
                  component={OrderCancel}
                />
                <Route
                  path={pages.search(":eventId", ":query")}
                  component={SearchPage}
                />
                <Route
                  path={pages.participant(":eventId", ":participantId")}
                  component={ParticipantGallery}
                />
                <Route
                  path={pages.gallery(":galleryId")}
                  component={ParticipantGallery}
                />
                <Route
                  path={pages.user}
                  component={Archive}
                />
                <Route
                  path={pages.login}
                  component={Login}
                />
                <Route
                  path={pages.signup}
                  component={Signup}
                />
                <Route
                  path={pages.forgotPassword}
                  component={ForgotPassword}
                />
                <Route path="/">
                  <Redirect to={pages.eventHome(featuredEvent?.id)}/>
                </Route>
              </Switch>
            </div>
          </div>
          <Footer/>
        </BrowserRouter>
      </div>
    )
  }
}

Router.contextType = MainContext
export default Router
