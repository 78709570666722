import classcat from 'classcat'
import React, {useContext, useState} from 'react'
import {Link, useLocation} from "react-router-dom"

import c from "./footer.module.css"
import TermsModal from "../Terms"
import {MainContext} from "../../state/state"


export default function Footer({className}) {
  const [modalStatus, setModalStatus] = useState(false)

  const location = useLocation()
  const {eventsData} = useContext(MainContext)

  const featuredEvent = eventsData?.find(e => e.featured)
  const currentEvent = eventsData?.find(e => location.pathname.includes(e.id)) || featuredEvent

  return (
    <div className={classcat([c.container, className])}>
      <div className={c.left}>
        <Link to="/">
          <div className={c.logo}>
            <img
              src={process?.env.PUBLIC_URL + "/imgs/logo-text.png"}
              alt="TAGGAD"
            />
          </div>
        </Link>
      </div>

      <div className={c.center}>
        <b>
          TAGGAD AB är officiell leverantör av deltagarfoton vid {currentEvent?.name}
        </b>
        <div>
          För frågor som gäller våra fototjänster vid {currentEvent?.name},
          <br/>
          kontakta &nbsp;
          <a href={`mailto:${currentEvent?.supportEmail}`}>{currentEvent?.supportEmail}</a>
        </div>
      </div>

      <div className={c.right}>
        <div className={c.gdprLine}>
          Tjänsteutbudet och prissättning kan variera mellan olika event.
          Mer information om <span
          className={c.termsLink}
          onClick={
            () => setModalStatus(true)
          }>GDPR och användarvillkor</span>.
          <hr/>
        </div>
        För frågor som gäller TAGGADs fotoplattform eller andra event, besök <a
        href="https://info.taggad.se">info.taggad.se</a>.
      </div>
      {
        modalStatus && (
          <TermsModal
            onClose={() => setModalStatus(false)}
          />
        )
      }
    </div>
  )
}