import { 
  SET_LOADING_BUY,
  SET_LOGIN_LOADER,
  SET_EVENTS_DATA,
  SET_USER,
  SET_BACKGROUND
}
  from './actions';

 
  
const handleIsLoadingBuy = (isLoadingBuy, state) => {
  return { ...state, isLoadingBuy }
}

const handleLoginLoader = (isLoginLoading, state) => {
  return { ...state, isLoginLoading }
}

const handleEventsData = (eventsData, state) => {
  return { ...state, eventsData }
}

const handleUserLog = (user, state) => {
  return {... state, user}
}

const setBackground = (background, state) => { 
  return {...state, background}
}

export const mainReducer = (state, action) => {
  switch (action.type) {  
  case SET_LOADING_BUY:
    return handleIsLoadingBuy(action.isLoadingBuy, state)
  case SET_LOGIN_LOADER:
    return handleLoginLoader(action.isLoginLoading, state)
  case SET_EVENTS_DATA:
    return handleEventsData(action.eventsData, state)
  case SET_USER:
    return handleUserLog(action.user, state)
  case SET_BACKGROUND:
    return setBackground(action.background, state)
  default:
    return state
  }
}

