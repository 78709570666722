import React, {Suspense} from 'react'
import ReactDOM from 'react-dom'
import Router from './Router'
import {GlobalProvider} from './state/state'
import reportWebVitals from './reportWebVitals'
import Loader from './components/Loader'

import './index.scss'
import './index.css'
import 'antd/dist/antd.css'

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loader/>}>
      <GlobalProvider>
        <Router/>
      </GlobalProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
