export const pages = {
  home: "/home",
  eventHome: (eventId) => `/home/${eventId}`,
  search: (eventId, query) => `/search/${eventId}/${query}`,
  participant: (eventId, participantId) => `/participant/${eventId}/${participantId}`,
  gallery: (galleryId) => `/gallery/${galleryId}`,
  login: "/login",
  forgotPassword: "/forgot-password",
  signup: "/signup",
  user: `/user`,
  order: (orderId) => `/order/${orderId}`,
  success: (orderId) => `/success/${orderId}`,
  cancel: (orderId) => `/cancel/${orderId}`,
}