const prod = true

const stageEnv = {
  taggadApi: "https://us-central1-taggad-4f62f.cloudfunctions.net/stage-api-front/",
  // taggadApi: "http://localhost:5001/taggad-4f62f/us-central1/stage-api-front",
  stripeKey: "pk_test_51HXmumGlZCWyrP0w79SuIZETcfrmswqgW1uokGVZdw4E3Uj48cEfR6IxDDOecYluMPOTHjbQc6lpS4Gk9ctfmGkX00pN2UFkcd"
}

const prodEnv = {
  taggadApi: "https://us-central1-taggad-4f62f.cloudfunctions.net/api-front/",
  stripeKey: "pk_live_51HXmumGlZCWyrP0wbt5EgzOnV5Qyrardt5leMCCnrAzJKRo0F2Dw2cLDlWqIaKpmZkMXcy1TIe2aAw404acUbdwX00mR5k3RFZ",
}

module.exports = prod ? prodEnv : stageEnv