import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const app = firebase.initializeApp({
  apiKey: "AIzaSyDP9z52rLlWI6sg9vmSKuG5JYWLaf1O5gU",
  authDomain: "auth.taggad.se",
  databaseURL: "https://taggad-4f62f.firebaseio.com",
  projectId: "taggad-4f62f",
  storageBucket: "taggad-4f62f.appspot.com",
  messagingSenderId: "909997339937",
  appId: "1:909997339937:web:22319b960a176276bf3a60",
  measurementId: "G-925Q6ZJ2PS"
});


export const auth = firebase.auth();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export default app;