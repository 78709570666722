import React, {useContext, useEffect, useState} from 'react'
import {Link} from "react-router-dom";

import {pages} from '../../utils/routes';
import {MainContext} from '../../state/state'
import { auth } from '../../firebase';

import c from "./topBar.module.css";

const MobileNav = () => {

  const [isDropped, setIsDropped] = useState(false);
  const {user} = useContext(MainContext);



  return (
    isDropped ? (
      <div className="dropnavs">
        <div 
          className={c.closeButton}
          onClick={() => setIsDropped(!isDropped)}
          dangerouslySetInnerHTML={{__html: "&times"}}
        /> 
        <Link onClick={() => setIsDropped(false)} to="/" className="nav-item">
          <p>
            Sök
          </p>
        </Link>
        <Link 
          onClick={() => setIsDropped(false)}
          to={"/user"}
          className="nav-item"
        >
          <p>
          Mitt Bildarkiv
          </p>
        </Link>
        {
          user ? (
            <div
              onClick={() => {
                setIsDropped(false)
                auth.signOut()
              }} 
            >
              Logga ut
            </div>
          ) : (
            <Link onClick={() => setIsDropped(false)}
              to={pages.login} className="nav-item">
              <div>
                Logga in
              </div>
            </Link>
          )  
        }
      </div>
    ) : ( 
      <div className={c.menuButton} onClick={() => setIsDropped(!isDropped)}>
        <div/>
        <div/>
        <div/>
      </div> 
    )
  )
}



export default function TopBar(props) {
  const [isMobile, setMobile] = useState(window.innerWidth < 700)
  const {user} = useContext(MainContext);

  useEffect(() => {
    function handleResize() {
      setMobile(window.innerWidth < 700);
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  });

  return (
    <div className={c.container}>
      <div className={c.bar}>
        <div className="row">
          {
            props.back && (
              <button
                className="back icon nav-item"
                onClick={props.back}>
                <i className="fa fa-chevron-left" aria-hidden="true"/>
              </button>
            )
          }
          <Link to="/">
            <div className={c.logo}>
              <img 
                src={process?.env.PUBLIC_URL + "/imgs/logo-text.png"}
                className="attachment-thumbnail size-thumbnail" 
                alt="TAGGAD"
              />
            </div>
          </Link>
        </div>
        {
          isMobile ?  (
            <MobileNav {...props} /> 
          ) : (
          <div className="row">
            <Link 
              to="/" 
              className={c.navItem}
            >
              <p>
                Sök
              </p>
            </Link> 
            <Link 
              to={pages.user}
              className={c.navItem}
            > 
              <p>
                Mitt Bildarkiv
              </p>
            </Link>
            {
              user ? (
                <div
                  onClick={() => auth.signOut()} 
                  className={c.navItem}
                >
                  Logga ut
                </div> 
              ) : (
                <Link 
                  className={c.navItem}
                  to={pages.login}
                >
                  <p>
                    Logga in
                  </p>
                </Link>
              )
            }
          </div>
        )
      }
      </div>
    </div>
  )
}