import classcat from 'classcat';
import React from 'react'

import c from "./loader.module.css";

const Loader = ({light}) => (
  <div className={classcat([c.background, light && c.light]) }>
    <div className={c.container}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
)

export default Loader;