import ajax from "./ajax"

const sendRequest = {
  get: (url, data) => {
    if (data) {
      const query = new URLSearchParams(data)
      url += "?" + query
    }

    return ajax({
      url
    })


  },
  post: (url, data) => {
    return ajax({
      method: "POST",
      url,
      data
    })
  },
  del: (url, data) => {
    return ajax({
      method: "DELETE",
      url,
      data
    })
  }
}


export default sendRequest
