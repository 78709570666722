import classcat from "classcat";
import React, { useEffect, useRef } from "react";


import c from "./modal.module.css";

export default function Modal({ children, onClose, className }) {
  const container = useRef();
  //preventing page scroll behind modal
  useEffect(() => {
    container.current.style.opacity = 1;
    document.body.style.overflow = "hidden";

    return () => document.body.style.overflow = "initial";
  }, []);

  const handleClose = (e) => {
    if(e.target.id === "modal-container" && onClose)
      onClose();
  }


  return (
    <div 
      ref={container}
      id="modal-container"
      className={"modal-container"}
      onClick={handleClose}
    >
      <div className={classcat([c.content, className]) }>
        {
          onClose && (
            <div className={c.closeButtonField}>
              <button className={c.closeButton} onClick={onClose} >&times;</button>
            </div>
          )
        }
        {children}
      </div>
    </div>
  )
}
