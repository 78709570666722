import axios from 'axios'
import {taggadApi} from "../env"

const ajax = axios.create({
  baseURL: taggadApi,
  timeout: 20000,
  headers: {
    Accept: 'application/json',
    'apikey': '121ebf71-e48b-43ac-b9c7-63f52a454e4d',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
})

// Add a request interceptor
ajax.interceptors.request.use(
  function (config) {
    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)

// Add a response interceptor
ajax.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    return Promise.reject(error)
  },
)

export default ajax
